*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App{
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
}
