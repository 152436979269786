.mainCNTLogin{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

form{
    display: flex;
    flex-direction: column;
    justify-content: center;
}


form div{
    margin-top: 10px;
}

button{
    margin-top: 15px;
}