.projectCard{
    width: 150px;
    height: 100px;
    border: solid;
    border-width: 1px;
    border-radius: 7px;
    padding: 20px;
    margin-right: 50px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.open{
    cursor: pointer;
    padding: 5px;
    background-color: #2db9f5;
    width: 55%;
    height: 30px;
    text-align: center;
    border-radius: 7px;
    margin-top: 10px;
}