.btn{
    cursor: pointer;
}

.projectNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    background-color: rgba(18, 18, 46);
}

.delete-btn{
    padding: 10px;
    background-color: red;
    border-radius: 10px;
    color: white;
}

.open-btn{
    padding: 10px;
    text-decoration: none;
    background-color: rebeccapurple;
    border-radius: 10px;
    color: white;
}
.firstCont{
    margin-bottom: 15px;
}
.firstCont h1{
    color: white;
    margin-bottom: 15px;
}

.inputCont{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-bottom: solid 1px;
    flex-wrap: wrap;
}
.inputCont p, .inputCont input{
    margin-right: 10px;
    margin-top: 10px;
}

.inputCont p{
    font-size: 18px;
}

.inputCont input{
    font-size: 16px;
    padding: 5px;
}
.add-btn{
    padding: 10px;
    border-radius: 10px;
    background-color: #2DB9F5;
    color: white;
    margin-top: 10px;
}