.Home{
    width: 100%;
    height: 100%;
}

.newProjectCont{
    width: 100%;
    padding: 15px;
    background-color: rgb(0, 0, 87);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.newProjectCont p{
    font-size: 16pt;
    color: white;
    margin-right: 20px;
    margin-top: 10px;
}
.newProjectCont input{
    margin-right: 20px;
    height: 35px;
    border: none;
    font-size: 10pt;
    padding: 3px;
    border-radius: 7px;
    margin-top: 10px;
}

.createBtn{
    cursor: pointer;
    background-color: #2DB9F5;
    padding: 13px;
    border-radius: 7px;
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 11pt;
    margin-left: 10px;
    margin-right: 10px;
    box-sizing: border-box;
    white-space: nowrap;
    margin-top: 10px;
}

.RecentProjectsMainCont{
    width: 100%;
    height: 90vh;
    background-color: #F0F0F0;
    overflow-y: scroll;
}

.projectTitle{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
}

.RecentProjectsCont{
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-wrap: wrap;
}