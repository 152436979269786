.captureCard{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    border-bottom: solid 1px;
}

.edit-btn{
    background-color: gray;
    border-radius: 10px;
    padding: 10px;
}